define("discourse/plugins/discord-sync/discourse/templates/connectors/group-edit/discord-roleid-group-settings", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="control-group">
    <label class="control-label" for="discord_role_id">{{i18n "groups.discord_sync_discord_role_id"}}</label>
    <p>{{i18n "groups.discord_sync_discord_role_id_warning"}}</p>
    {{text-field value=group.custom_fields.discord_role_id classNames="input-setting-string"}}
  </div>
  
  */
  {
    "id": "gKWlMvDb",
    "block": "[[[10,0],[14,0,\"control-group\"],[12],[1,\"\\n  \"],[10,\"label\"],[14,0,\"control-label\"],[14,\"for\",\"discord_role_id\"],[12],[1,[28,[35,2],[\"groups.discord_sync_discord_role_id\"],null]],[13],[1,\"\\n  \"],[10,2],[12],[1,[28,[35,2],[\"groups.discord_sync_discord_role_id_warning\"],null]],[13],[1,\"\\n  \"],[1,[28,[35,4],null,[[\"value\",\"classNames\"],[[30,0,[\"group\",\"custom_fields\",\"discord_role_id\"]],\"input-setting-string\"]]]],[1,\"\\n\"],[13],[1,\"\\n\"],[1,[28,[32,0],[\"[[\\\"The `group` property path was used in the `discourse/plugins/discord-sync/discourse/templates/connectors/group-edit/discord-roleid-group-settings.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.group}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"div\",\"label\",\"i18n\",\"p\",\"text-field\"]]",
    "moduleName": "discourse/plugins/discord-sync/discourse/templates/connectors/group-edit/discord-roleid-group-settings.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});