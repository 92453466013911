import { template as template_3e0c4e5753ed45e69fc8decaa5ae0faf } from "@ember/template-compiler";
const SidebarSectionMessage = template_3e0c4e5753ed45e69fc8decaa5ae0faf(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
