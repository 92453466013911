import { template as template_d8267225962b44b8a9fbe304ec007ca5 } from "@ember/template-compiler";
import NotificationsTracking from "discourse/components/notifications-tracking";
import { i18n } from "discourse-i18n";
const CategoryNotificationsTracking = template_d8267225962b44b8a9fbe304ec007ca5(`
  <NotificationsTracking
    @onChange={{@onChange}}
    @levelId={{@levelId}}
    @showCaret={{@showCaret}}
    @showFullTitle={{@showFullTitle}}
    @prefix="category.notifications"
    @title={{i18n "category.notifications.title"}}
    class="category-notifications-tracking"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default CategoryNotificationsTracking;
