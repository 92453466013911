import { template as template_276fd26a88684d8b8039c53ca1c9e94e } from "@ember/template-compiler";
import icon from "discourse-common/helpers/d-icon";
const InputTip = template_276fd26a88684d8b8039c53ca1c9e94e(`
  <div
    class="tip {{if @validation.failed 'bad' 'good'}}"
    id={{@id}}
    ...attributes
  >
    {{#if @validation.reason}}
      {{icon (if @validation.failed "xmark" "check")}}
      {{@validation.reason}}
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default InputTip;
